
export const calculateWeight = (newPercentageValue, variant, variants) => {
  const curPercentageVal = (variant.weight || 0) * 100;
  let newWeight = Math.round((Number(newPercentageValue) || 0) * 100) / 10000;

  // calculate sum of the other existing variants
  let otherVariantsWeight = 0;
  variants.forEach(e => {
    if (variant.id === e.id) {
      return;
    }
    otherVariantsWeight += e.weight;
  });

  const newTotalWeight = otherVariantsWeight + newWeight; // new total weight including new weight
  const prevTotalWeight =  otherVariantsWeight + variant.weight; // total weight including previous weight

  if (newTotalWeight > 1) {
    if (prevTotalWeight === 1) {
      return;
    }

    const increasedWeight = newTotalWeight - 1; // increased total weight from 1
    let restSum = 1 - otherVariantsWeight; // maxmimum affordable weight

    if (restSum < increasedWeight) {
      return restSum;
    }

    return newWeight - increasedWeight;

  } else if (newTotalWeight === 1) {
    if (prevTotalWeight === 1 && newPercentageValue >= curPercentageVal) {
      return;
    }

    return newWeight;
  }

  return newWeight;
}
