import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {engineMap, metricMap, metrics} from '../utils/metadata';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1, Levander,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1, Mint,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from "../utils/formatter";
import Btn from "../uikit/btn";
import {TooltipContainer} from "./timeseries.style";
import {Table} from "../uikit";
import {BarWrapper, SelectInlineStyled} from "./index.style";
import * as d3 from "d3";
import {ChartWrapper, UnitItem} from './histogram.style';

const colors = [BlueJeans, Grass];
const colorsSecond = [BitterSweet, Sunflower];

export default class extends Component {

  constructor(props) {
    super(props);
    const {dimension, items = []} = props.data || {}
    this.state = {
      unit: 'daily',
      metric: props.metric || 'impressions',
      secondaryMetric: props.secondaryMetric || null,
      inactiveMap: {},
      chartType1: 'bar',
      chartType2: 'line'
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    const {availableMetrics = [], hideBenchmark} = this.props;
    const {metric, secondaryMetric, unit, inactiveMap, chartType1, chartType2} = this.state;
    return (
      <ChartWrapper>
        <div className="chart-header">
          <div className="chart-legent">
            {
              !hideBenchmark &&
              <>
                &nbsp;&nbsp;&nbsp;
                <div className={classnames(`legent-item rect`, {inactive: !!inactiveMap.boost})} onClick={e => {
                  this.setState({inactiveMap: {...inactiveMap, boost: !inactiveMap.boost}})
                }}>
                  <div className='rect' style={{backgroundColor: colors[0]}}></div>
                  {!!secondaryMetric && chartType2 === chartType1 && <div className='rect' style={{backgroundColor: colorsSecond[0]}}></div>}
                  Boost
                </div>
                <div className={classnames(`legent-item rect`, {inactive: !!inactiveMap.ta})} onClick={e => {
                  this.setState({inactiveMap: {...inactiveMap, ta: !inactiveMap.ta}})
                }}>
                  <div className='rect' style={{backgroundColor: colors[1]}}></div>
                  {!!secondaryMetric && chartType2 === chartType1 && <div className='rect' style={{backgroundColor: colorsSecond[1]}}></div>}
                  Think Analytics
                </div>
              </>
            }
            {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
            {/*<div className={`legent-item rect`}>*/}
            {/*  <div className='rect' style={{backgroundColor: 'white'}}></div>*/}
            {/*  */}
            {/*</div>*/}
            {/*<div className={`legent-item rect`}>*/}
            {/*  <div className='line' style={{backgroundColor: MediumGray}}></div>*/}
            {/*  <SelectInlineStyled*/}
            {/*    style={{marginRight: '-15px', marginLeft: '-10px'}}*/}
            {/*    selected={secondaryMetric}*/}
            {/*    data={metrics.filter(d => {*/}
            {/*      return availableMetrics.indexOf(d.key) >= 0 && d.type === 'efficiency';*/}
            {/*    })}*/}
            {/*    mobileAdjust={true}*/}
            {/*    useFixed={true}*/}
            {/*    onChange={metric => {*/}
            {/*      this.setState({secondaryMetric: metric})*/}
            {/*    }}/>*/}
            {/*</div>*/}
          </div>
          <div className="chart-actions">
            <span style={{color: DarkGray, marginRight: '5px'}}>Left Y-axis:</span>
            <SelectInlineStyled
              style={{marginRight: '15px'}}
              selected={metric}
              labelSubfix={(secondaryMetric && chartType1 !== chartType2) ? ` (${chartType1})` : null}
              data={availableMetrics.map(metric => metrics.find(m => m.key === metric))}
              mobileAdjust={true}
              menuHeader={(
                <div className="chart-type-selector">
                  {
                    ['bar', 'line'].map(type => {
                      return (
                        <div
                          key={type}
                          className={classnames({selected: chartType1 === type})}
                          onClick={e => this.setState({chartType1: type})}>
                          {_.capitalize(type)} Chart
                        </div>
                      )
                    })
                  }
                </div>
              )}
              onChange={metric => {
                this.setState({metric: metric})
              }}/>
              <span style={{color: DarkGray, marginRight: '5px'}}>Right Y-axis:</span>
              <SelectInlineStyled
                style={{marginRight: '-5px', marginLeft: '-10px'}}
                selected={secondaryMetric}
                labelSubfix={(secondaryMetric && chartType1 !== chartType2) ?` (${chartType2})` : null}
                data={[
                  {key: 'NONE', label: 'NONE'},
                  ...(availableMetrics.map(metric => metrics.find(m => m.key === metric)))
                ]}
                mobileAdjust={true}
                menuHeader={(
                  <div className="chart-type-selector">
                    {
                      ['bar', 'line'].map(type => {
                        return (
                          <div
                            key={type}
                            className={classnames({selected: chartType2 === type})}
                             onClick={e => this.setState({chartType2: type})}>
                            {_.capitalize(type)} Chart
                          </div>
                        )
                      })
                    }
                  </div>
                )}
                onChange={metric => {
                  this.setState({secondaryMetric: metric})
                }}/>
            {/*<div style={{textAlign: 'right'}}>*/}
            {/*  {*/}
            {/*    ['daily', 'weekly', 'monthly'].map(t => {*/}
            {/*      return (*/}
            {/*        <UnitItem*/}
            {/*          key={t}*/}
            {/*          onClick={e => this.setState({unit: t})}*/}
            {/*          className={classnames({selected: unit === t})}>*/}
            {/*          {_.capitalize(t)}*/}
            {/*        </UnitItem>*/}
            {/*      )*/}
            {/*    })*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </div>
        <div ref={this.containerRef} key={Date.now()}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    const {hideBenchmark} = this.props;
    const {period, items = []} = this.props.data || {};
    const {metric, secondaryMetric, unit, inactiveMap, chartType1, chartType2} = this.state;
    const metricMeta = metrics.find(d => d.key === metric);
    const secondMetricMeta = metrics.find(d => d.key === secondaryMetric);
    let nowMnt = moment();
    // const data = _.range(0, 30).map(i => {
    //   return {
    //     date: nowMnt.add(-i, 'day').format('YYYY-MM-DD'),
    //     boost_impressions: _.random(1000, 500),
    //     boost_ctr: _.random(0, 10) / 100,
    //     ta_impressions: _.random(1000, 500),
    //     ta_ctr: _.random(0, 10) / 100,
    //   }
    // })
    const dates = _.uniq(items.map(d => d.date)).sort();

    const container = this.containerRef.current;
    let height = 200;
    const myChart = echarts.init(container, null, {renderer: 'svg', height: height});
    let tooltipIndex = -1;
    var option = {
      color: colorCategory1,
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'filter',
          // start: 0,
          height: 20,
          backgroundColor: 'white',
          fillerColor: 'rgba(135, 175, 255, 0.1)',
          moveHandleStyle: {
            color: 'rgba(250,250,250, 0.01)'
          },
          borderColor: 'white',
          emphasis: {
            moveHandleStyle: {
              color: 'rgba(250,250,250, 0.01)'
            },
          }
        }
      ],
      xAxis: {
        data: dates,
        axisLabel: {
          formatter: (value, i) => {
            return moment(value).format(i ? 'MMM DD' : 'MMM DD, YYYY')
          }
        }
      },
      yAxis: [
        {
          type: 'value',
          name: metricMap[metric],
          nameLocation: 'start',
          splitNumber: 3,
          nameGap: 25,
          splitLine: {
            show: false
          },
          axisLabel: {
            inside: true,
            margin: -40,
            formatter: function (value) {
              return (metricMeta.formatter1 || metricMeta.formatter)(value);
            }
          }
        },
        {
          type: 'value',
          name: metricMap[secondaryMetric],
          nameLocation: 'start',
          splitNumber:3,
          nameGap: 25,
          splitLine: {
            show: false
          },
          axisLabel: {
            inside: true,
            margin: -40,
            formatter: function (value) {
              return (secondMetricMeta.formatter1 || secondMetricMeta.formatter)(value);

            }
          }
        }
      ],
      grid: {
        bottom: '80',
        top: 10,
        left: '70px',
        right: !secondaryMetric ? '10px' : '60px'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value, i) => {
          tooltipIndex++;
          tooltipIndex = secondaryMetric ? (tooltipIndex % 4) : (tooltipIndex % 2);
          if(secondaryMetric && tooltipIndex > 1) {
            return secondMetricMeta ? (secondMetricMeta.formatter1 || secondMetricMeta.formatter)(value) : intFormatter(value);
          } else {
            return metricMeta ? (metricMeta.formatter1 || metricMeta.formatter)(value) : intFormatter(value);
          }
        },
        enterable: true,
        // formatter: (params) => {
        //   const date = (params[0] || {}).name;
        //   const items = data.filter(d => (!closeState[d[dimension]])).map(d => {
        //     return {
        //       ...(d.data[dates.indexOf(date)]),
        //       [dimension]: d[dimension],
        //     }
        //   });
        //   return this.renderTooltip(date, items, colorScale);
        // }
      },
      series: _.compact([
        ..._.compact(['boost', 'ta'].map((engine, i) => {
          if(inactiveMap[engine]) return null;
          return {
            name: engineMap[engine] + ' ' +metricMap[metric],
            // type: metricMeta.type === 'cumulative' ? 'bar' : 'line',
            type: chartType1 || 'bar',
            barCategoryGap: '30%',
            barMaxWidth: 20,
            barWidth: (secondaryMetric && chartType1 === chartType2) ? '12%' : '25%',
            showSymbol: true,
            connectNulls: true,
            smooth: false,
            itemStyle: {
              color: colors[i],
              borderRadius: [2,2, 0, 0]
            },
            emphasis: {
              focus: 'series'
            },
            data: items.filter(d => d.engine === engine).map(d => d[metric])
          };
        })),
        ..._.compact(['boost', 'ta'].map((engine, i) => {
          if(!secondaryMetric || secondaryMetric === 'NONE' || inactiveMap[engine]) return null;
          return {
            yAxisIndex: 1,
            name: engineMap[engine] + ' ' +metricMap[secondaryMetric],
            type: chartType2 || 'line',
            barCategoryGap: '30%',
            barMaxWidth: 20,
            barWidth: (secondaryMetric && chartType1 === chartType2) ? '12%' : '25%',
            showSymbol: false,
            connectNulls: true,
            smooth: true,
            endLabel: {show: false},
            data: items.filter(d => d.engine === engine).map(d => d[secondaryMetric]),
            itemStyle: {
              color: (chartType2 === chartType1 ? colorsSecond: colors)[i],
            },
            lineStyle: {
              opacity: 0.8,
              width: 2
            },
            areaStyle: {
              opacity: 0.2,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colors[i]
                },
                {
                  offset: 1,
                  color: 'white'
                }
              ])
            }
          }
        })),
      ])
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
    this.myChart = myChart;
  }
}
