import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {Table, TableScrollWrapper, Btn, CondirmDialog, Hint} from '../uikit/index';
import VariantSelector from './variant-selector';
import PreviewBox from '../widgets/previewBox';
import {VariantsWrapper, NoData, SliderStyled, TagList} from './step-variants.style';
import {calculateWeight} from './variant-weight-calculator';
import {filterTags} from "../utils/formatter";
import {abTestTargetOptions, algorithms} from "../utils/metadata";
import {MediumGray} from "../app/StyleCommon";
import {SelectStyled} from "./select.style";
import {SelectInlineStyled} from "../dashboard/index.style";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      abtesting: this.props.abtesting
    };
  }

  render() {
    const {abtesting} = this.state;
    const {variants = [], dynamicAllocationEnabled, dynamicAllocationRatio, isEngineSticky} = abtesting || {};
    const {appState: {session}} = this.props;
    const isAdmin = (session || {}).isAdmin;
    return (
      <VariantsWrapper className="step-variants">
        {(!variants || !variants.length) &&
        <NoData>
          <span className="blockIfMobile">No variants found.</span>
          <span className="blockIfMobile">Please <a onClick={() => this.selectVariants(abtesting)}>add</a> variants from here.</span>
        </NoData>
        }
        {!!variants && variants.length > 0 &&
        <div>
          <div className="variants-list-header">
            <p></p>
            {
              variants.length > 1 &&
              <Btn
                type="link"
                className="add-variant"
                style={{marginRight: '30px'}}
                onClick={() => this.evenlyAbTestingPercentage(abtesting)}>
                <i className="fa fa-rocket"/> Evenly split
              </Btn>
            }

            <Btn
              type="link"
              className="add-variant"
              onClick={() => this.selectVariants(abtesting)}>
              <i className="fa fa-plus"/> Add
            </Btn>
          </div>
          <TableScrollWrapper className="variants-list-body">
            <Table
              rowSortable={true}
              onReplaceRow={(a, b) => this.swapVariants(a, b)}
              columns={_.compact([
                {
                  key: '',
                  label: (
                    <span>Control <Hint useFixed={true} width={250} content={
                      <ul style={{textAlign: 'left', color: 'white'}}>
                        The selected control widget will be used to benchmark lifts for this A/B test.
                      </ul>
                    }/></span>
                  ),
                  align: 'center',
                  style: {width: '50px'},
                  renderer: (d, i) => {
                    return (
                      <span className="variant-itm">
                        {d.isControl &&
                        <i className="control fa fa-check" aria-hidden="true"></i>
                        }
                        {!d.isControl &&
                        <i className="default fa fa-check" aria-hidden="true"
                           onClick={() => this.setControl(abtesting, i)}></i>
                        }
                      </span>
                    )
                  }
                },
                {
                  key: '',
                  label: '',
                  align: 'center',
                  hidden: !variants || !variants.length,
                  renderer: (d, index) => {
                    return (
                      <div className="widget-name-card draggble">
                        <i className="fa fa-bars" style={{color: MediumGray}}/>
                      </div>
                    )
                  }
                },
                {
                  key: 'name',
                  label: 'Widget Name',
                  align: 'left',
                  renderer: (d, index) => {
                    let algorithmMeta = algorithms.find(a => a.key === d.algorithm);
                    let backfillMeta = algorithms.find(a => a.key === d.backfill);
                    return (
                      <div className="widget-name-card">
                        <a className="name" onClick={e => this.showPreviewPopup(d)}>
                          {d.name}, ID: <strong>{d.id}</strong>
                        </a>
                        <TagList>
                          {filterTags(d.tags, isAdmin).map(t => {
                            return <span className={`tag ${t.split('::')[0]}`} key={t}>{t.split('::').pop()}</span>
                          })}
                          {isAdmin && !!d.algorithm && <span className="tag algo">{algorithmMeta ? algorithmMeta.label: d.algorithm}</span>}
                          {!isAdmin && d.algorithm === 'Think Analytics' &&<span className="tag">TA</span>}
                        </TagList>
                      </div>
                    )
                  }
                },
                isEngineSticky ? {
                  key: 'platform',
                  label: 'Platform (Optional)',
                  align: 'center',
                  style: {minWidth: '100px'},
                  renderer: (d, index) => {
                    return (
                      <SelectInlineStyled
                        useFixed={true}
                        defaultLabel={'Select...'}
                        selected={d.target_platform || 'all'}
                        data={[
                          {key: 'all', label: 'All'},
                          {key: 'web', label: 'Web'},
                          {key: 'app', label: 'App'},
                        ]}
                        onChange={p => {
                          d.target_platform = p;
                          this.saveAbTesting(abtesting);
                        }}/>
                    )
                  }
                }: null,
                {
                  key: 'weightEditor',
                  label: 'Weight',
                  align: 'center',
                  style: {minWidth: '100px'},
                  renderer: (d, index) => this.renderSlider(d, index)
                },
                {
                  key: 'actions',
                  label: 'Actions',
                  align: 'center',
                  style: {width: '80px'},
                  renderer: (d, i) => (
                    <div className="action-items">
                      <Btn
                        type="link"
                        className="edit-btn"
                        onClick={e => this.selectVariants(abtesting, 'edit', d, i)}><i
                        className="fa fa-edit"/>
                      </Btn>
                      <Btn
                        type="link"
                        className="delete-btn"
                        onClick={e => this.removeVariant(abtesting, i)}><i
                        className="fa fa-trash"/>
                      </Btn>
                    </div>
                  )
                },
              ])}
              rows={variants || []}
              noDataText={<span>No variants added</span>}
            />
          </TableScrollWrapper>
          {/*{*/}
          {/*  variants.length > 1 &&*/}
          {/*  <div className={`variants-list-footer ${dynamicAllocationEnabled ? '' : 'disabled'}`}>*/}
          {/*    <label>*/}
          {/*      <input*/}
          {/*        type="checkbox"*/}
          {/*        checked={dynamicAllocationEnabled}*/}
          {/*        onChange={e => {*/}
          {/*          if(e.currentTarget.checked) {*/}
          {/*            abtesting.dynamicAllocationEnabled = true;*/}
          {/*            abtesting.dynamicAllocationRatio = abtesting.dynamicAllocation || 0;*/}
          {/*          } else {*/}
          {/*            abtesting.dynamicAllocationEnabled = false;*/}
          {/*          }*/}
          {/*          this.saveAbTesting(abtesting);*/}
          {/*        }}/>*/}
          {/*      Enable dynamic traffic allocation {dynamicAllocationEnabled}*/}
          {/*    </label>*/}
          {/*    <div className="slider-wrap">*/}
          {/*      <SliderStyled*/}
          {/*        min={0}*/}
          {/*        max={80}*/}
          {/*        disabled={!dynamicAllocationEnabled}*/}
          {/*        value={(dynamicAllocationRatio || 0) * 100}*/}
          {/*        onChange={newValue => {*/}
          {/*          abtesting.dynamicAllocationRatio = Math.round(newValue) / 100;*/}
          {/*          this.saveAbTesting(abtesting);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <label type="number">{((dynamicAllocationRatio || 0) * 100).toFixed()} %</label>*/}
          {/*      <Hint pos="left"*/}
          {/*            useFixed={true} width={250} content={*/}
          {/*        <div style={{textAlign: 'left', color: 'white'}}>*/}
          {/*          <p>You can set dynamic allocation for part of traffic, the rest of the traffic will be allocated base on the variants weightage listed above.</p>*/}
          {/*          <p>The dynamic allocation part </p>*/}
          {/*        </div>*/}
          {/*      }/>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*}*/}
        </div>
        }
        <div style={{margin: '20px 0'}}>
          <label style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}>
            <input
              type="checkbox"
              style={{marginRight: '10px'}}
              checked={isEngineSticky} onChange={e => {
              this.saveAbTesting({...abtesting, isEngineSticky: e.target.checked});
            }}/>
            Always assign traffic to the same recommendation engine for the same user.
          </label>
        </div>
      </VariantsWrapper>
    )
  }

  swapVariants(index1, index2) {
    const {abtesting} = this.state;
    const {variants = []} = abtesting || {};
    const exchange = variants[index1];
    variants[index1] = variants[index2];
    variants[index2] = exchange;
    this.saveAbTesting({...abtesting, variants: [...variants]});
  }

  renderSlider(variant, index) {
    const percentage = Math.round((variant.weight || 0) * 10000) / 100;
    return (
      <div className="slider-wrap">
        <SliderStyled
          min={0}
          max={100}
          value={percentage}
          onChange={newValue => this.updateAbTestingPercentage(newValue, variant)}
          />
        <label type="number">
          <input
            type="number" min={0} max={100}
            value={Number(percentage)}
            onChange={e => this.updateAbTestingPercentage(Number(e.target.value), variant)}
          /> %
        </label>
      </div>
    )
  }

  showPreviewPopup(widget) {
    const {abtesting} = this.state;
    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === abtesting.site);
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      width: '650px',
      hideCancel: true,
      hideOK: true,
      dialogBody: <PreviewBox id={widget.id} site={abtesting.site} siteOption={siteOption}/>,
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  saveAbTesting(abtesting) {
    this.props.onChange(abtesting);
    this.setState({abtesting: abtesting});
  }

  selectVariants(abtesting, action, currentItem, index) {
    const {variants = [], site} = abtesting || {};
    const {appState: {session = {}}} = this.props;
    const confirmInfo = {
      type: 'form',
      backgroundClose: false,
      title: <strong>Select Existing Widgets</strong>,
      width: '850px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <VariantSelector
        site={site}
        abtesting={abtesting}
        variants={variants}
        newInstanceId={session.newInstanceId}
        onAdd={variant => {
          if (action === 'edit') {
            variant.isControl = currentItem.isControl;
            variant.weight = currentItem.weight;

            variants[index] = variant;
          } else {
            this.updateWeights(variants, variant);
          }

          this.saveAbTesting({...abtesting, variants: [...variants]});
          CondirmDialog.closeAll();
        }}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  setControl(abtesting, index) {
    const {variants = []} = abtesting || {};

    const previousControl = variants.find(item => item.isControl === true);
    previousControl.isControl = false;

    let cur = variants[index];
    cur.isControl = true;

    this.saveAbTesting(abtesting);
  }


  removeVariant(abtesting, index) {
    const {variants} = abtesting;
    const updatedVariants = variants.filter((x, i) => i !== index);

    if (updatedVariants.length > 0 && !updatedVariants.find(d => d.isControl)) {
      updatedVariants[0].isControl = true;
    }

    this.saveAbTesting({...abtesting, variants: updatedVariants});
  }

  updateWeights(variants, newVariant) {
    if (!variants.length) {
      newVariant.weight = 1;
    } else {
      newVariant.weight = 0;
    }

    variants.push(newVariant);
  }

  evenlyAbTestingPercentage() {
    const {abtesting} = this.state;
    const {variants = []} = abtesting || {};
    const newWeight = 1 / variants.length;
    variants.forEach(v => {
      v.weight = Number((newWeight).toFixed(2));
    })
    this.saveAbTesting(abtesting);
  }

  updateAbTestingPercentage(newPercentageValue, variant) {
    const {abtesting} = this.state;
    const {variants = []} = abtesting || {};

    // variant.weight = (Number(newPercentageValue) / 100).toFixed(2);
    // this.saveAbTesting(abtesting);

    const newWeight = calculateWeight(newPercentageValue, variant, variants);

    if (!isNaN(newWeight)) {
      variant.weight = Number((newWeight).toFixed(4));
      this.saveAbTesting(abtesting);
    }
  }
})
