import styled, {css} from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {DarkGray, LightGray, LightGray1, BitterSweet1, BitterSweet, BlueJeans, MediumGray} from '../app/StyleCommon';

export const TagList = styled.div`
  display: flex;
  padding: 0 !important;
  flex-wrap: wrap;
  .tag {
    border: 1px solid ${LightGray1};
    white-space: nowrap;
    border-radius: 5px;
    font-size: 11px;
    display: inline !important;;
    background: #dbf1c4;
    font-weight: bold;
    margin: 5px 5px 5px 0 !important;
    padding: 0 5px !important;
    &.project {
      background: #d79beb78;
    }
    &.platform {
      background: #57c5f766;
    }
    &.page {
      background: #a0d46894;
    }
    &.algo {
      background: #ffce54ab;
    }
    &.backfill {
      background: #f1f2f3;
      font-size: 9px;
    }
  }
`
export const VariantsWrapper = styled.div`
  table {
    overflow: visible;
    border-collapse: separate;
    border-radius: 5px;
    background: ${LightGray};
    thead {
      background: ${LightGray};
      border: none;
      tr {
        th {
          color: ${DarkGray};
          font-weight: normal;
          background: transparent;
          &:first-child {
            border-top-left-radius: 5px;
            background-color: ${LightGray};
            border: 0;
            border-right: 1px solid ${LightGray1};
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-right: none;
          }
        }
      }
    }
    tbody {
      tr {
        background: white;
        &:hover {
          background-color: ${LightGray} !important;
          td: nth-child(1),
           td: nth-child(2){
            cursor: move;
          }
          .sorter {
            opacity: 1;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid ${LightGray};
          td {
            border-bottom: 1px solid ${LightGray};
          }
        }
        &:last-child td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
        td {
          background: white;
          font-size: 14px;
          padding: 5px;
          &:not(:last-child) {
            border-right: 1px solid ${LightGray};
          }
          &:first-child {
            border: 0;
            border-right: 1px solid ${LightGray1};
          }
          &:last-child {
            max-width: 30px;
          }
          .variant-itm {
            font-weight: bold;
            font-size: 16px;
            i.control {
              color: ${DarkGray};
            }
            i.default {
              color: #fff;
              padding: 5px 20px;
              &:hover {
                cursor: pointer;
                background-color: ${LightGray};
              }
            }
          }
          .widget-name-card {
            padding: 5px;
            @media screen and (max-width: 667px) {
              font-size: 12px;
            }
            .name {
              margin: 0;
              padding: 0;
              img {
                width: 18px;
                margin-right: 5px;
                vertical-align: middle;
              }
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
            .description {
              margin: 0;
              padding: 0;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .variants-list-header {
    text-align: right;
    .add-variant {
      padding: 5px 0;
      box-sizing: border-box;
    }
  }
  .variants-list-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    &.disabled {
      color: ${MediumGray};
    }
    > label {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    input[type=checkbox] {
      margin-right: 5px;
    }
    .slider-wrap {
      width: 150px;
      >label {
        text-align: right;
        width: auto;
      }
    }
  }
  .slider-wrap {
    display: flex;
    align-items: center;
    margin: 0 10px;
    .rc-slider {
      flex-grow: 1;
      margin: 0 20px 0 0;
    }
    label {
      width: 60px;
      font-size: 12px;
      // border: 1px solid ${LightGray1};
      border-radius: 5px;
      display: flex;
      //background: ${LightGray};
      input[type=number] {
        display: inline;
        border: none;
        outline: none;
        font-size: 12px;
        width: 50px;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
         opacity: 1;
      }
    }
  }
  .action-items {
    white-space: nowrap;
    .delete-btn {
      color: ${BitterSweet};
      font-size: 16px;
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${DarkGray};
  @media screen and (max-width: 667px) {
    display: block;
    .blockIfMobile {
      display: block;
      margin: 10px 0;
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    margin: 0 5px;
  }
`;

export const SliderStyled = styled(Slider)`
  margin: 0 20px;
  width: auto !important;
  .rc-slider-mark {
    top: 12px;
  }
  .rc-slider-track {
    background-color: #a8d2f9;
  }
  .rc-slider-handle {
    border-color: #a8d2f9;
  }
`;

export const VariantSelectorWrapper = styled.div`
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  margin-top: -20px;
  .actions {
    text-align: right;
    .selectBtn {

    }
  }
  table {
    margin: 20px 0;
  }
  .tag-list {
    display: flex;
    padding: 0 !important;
    flex-wrap: wrap;
    .tag {
      border: 1px solid ${LightGray1};
      white-space: nowrap;
      border-radius: 5px;
      font-size: 11px;
      display: inline !important;;
      background: #dbf1c4;
      font-weight: bold;
      margin: 5px 5px 5px 0 !important;
      padding: 0 5px !important;
    }
  }
  .rc-pagination li{
    min-width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .preview-link {
    //cursor: pointer;
    word-break: break-all;
    //&:hover {
    //  text-decoration: underline;
    //}
    img {
      width: 18px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .searchBox {
    flex-grow: 1;
    margin-top: 10px;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    input {
      padding: 8px 10px 8px 30px;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid ${MediumGray};
      background: ${LightGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
`;

export const ErrorBox = styled.div`
  margin: 10px 0;
  color: ${BitterSweet1};
`;
